/*
 * Copyright 2022 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";
@import "../../../views/components/forms/forms";

.advanced-settings {
  display:    block;
  width:      100%;

  margin:     5px 0 0;
  padding:    15px 0 5px;
  border-top: 1px solid $line-color;

  .summary {
    @include icon-before($type: $fa-var-chevron-right, $color: $text-color, $size: 16px, $line-height: 25px, $margin: 0 5px);

    line-height: 25px;
    cursor:      pointer;
    outline:     none;
    font-weight: 600;
  }

  .details {
    margin:  0;
    padding: 0;
    display: none;
  }

  &.open, &.lock-open {
    .summary {
      margin-bottom: 15px;

      &:before {
        transform: rotate(90deg);
      }
    }

    .details {
      display: block;
    }
  }

  &.lock-open .summary {
    cursor: not-allowed;

    &:before {
      color: lighten($text-color, 50%);
    }
  }
}

.selection-container {
  display:     flex;
  align-items: center;

  .message {
    margin-left: 10px;
    margin-top:  10px;
  }
}

.config-values {
  margin-left: 10px;
}
