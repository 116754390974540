/*
 * Copyright 2022 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "vals";

.fillable {
  background-color: $white;
  max-width: $fillable-section-width-max;
  min-width: $fillable-section-width-min;
  margin: $fillable-section-margin auto;
  padding: $fillable-section-margin;
  display: flex;
  justify-content: space-between;


  &.multi-section {
    display: block;

    .sub-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: $fillable-section-margin;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
