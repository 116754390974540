/*
 * Copyright 2022 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

.icon-delete {
  @include icon-before($type: $fa-var-times);
  border:  none;
  display: inline;
  cursor:  pointer;
  width:   22px;
  height:  22px;
  padding: 0;

  &:before {
    margin: 0;
  }

  &:focus {
    outline: none;
  }
}

.pipeline-group-name-wrapper {
  display: flex;
}

.pipeline-group-tooltip-wrapper {
  margin-top: 30px;
}

.permission-checkbox-wrapper {
  display: flex;
}

.cancel-button-wrapper {
  display:       flex;
  margin-bottom: 10px;
}

.permission-name-wrapper {
  margin-top: 10px;
}

.errors {
  padding: 0;
  margin:  5px;
}
