/*
 * Copyright 2022 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

$boxshadow: rgba(0, 0, 0, 0.15);
$opacity: 0.2;

.login-container {
  padding:    20px 30px 30px 20px;
  background: $white;
  min-width:  410px;
  min-height: 300px;

  &:before {
    background:    lighten($go-primary, 10%);
    content:       "";
    display:       block;
    width:         100px;
    height:        100px;
    border-radius: 50%;
    opacity:       $opacity;
    position:      absolute;
    left:          -30px;
    z-index:       map_get($zindex, logindesign);

  }

  &:after {
    background:    lighten($go-primary, 10%);
    content:       "";
    display:       block;
    width:         100px;
    height:        100px;
    border-radius: 50%;
    opacity:       $opacity;
    position:      absolute;
    right:         -28px;
    bottom:        50px;
    z-index:       map_get($zindex, logindesign);

  }


}

.login-form {
  display:        flex;
  flex-direction: column;
  align-items:    center;
  box-shadow:     1px 1px 10px $boxshadow;
  background:     $white;
  position:       absolute;
  left:           50%;
  top:            50%;
  transform:      translate(-50%, -60%);
  border-radius:  $global-border-radius;

  &:before {
    background:    lighten($go-primary, 10%);
    content:       "";
    display:       block;
    width:         30px;
    height:        30px;
    border-radius: 50%;
    opacity:       $opacity;
    position:      absolute;
    left:          -36px;
    top:           115px;
    z-index:       map_get($zindex, logindesign);

  }

  &:after {
    background:    lighten($go-primary, 10%);
    content:       "";
    display:       block;
    width:         30px;
    height:        30px;
    border-radius: 50%;
    opacity:       $opacity;
    position:      absolute;
    right:         -36px;
    bottom:        27px;
    z-index:       map_get($zindex, logindesign);

  }
}

.login-graphics {
  width:  100px;
  margin: 0 auto 30px;
}

.login-methods {
  display:         flex;
  flex-direction:  column;
  justify-content: center;
  align-items:     center;
  font-size:       16px;
}

.login-user {
  width: 350px;
}

.login-button {
  background:    $go-primary;
  width:         200px;
  height:        40px;
  line-height:   40px;
  color:         $white;
  font-weight:   600;
  font-size:     16px;
  border-radius: $global-border-radius;
  border:        none;
}

.login-form-fields {
  list-style-type: none;
  margin:          0;
  padding:         0;
  text-align:      center;


  &:active {
    border: none;
  }

  > li {
    margin-bottom: 30px;
  }
}

.login-input {
  border:        none;
  border-bottom: 1px solid $border-color;
  width:         300px;
  height:        35px;
  padding:       0 10px;
  box-sizing:    border-box;

  &::placeholder {
    color: $icon-color;

  }

  &:focus {
    outline: none;
  }
}

.other-login-methods {
  text-align: center;

}

.login-options {
  display:         flex;
  margin:          0 auto;
  justify-content: center;
  position:        relative;

}

.web-login-link {
  display:       block;
  border:        1px solid $border-color;
  border-radius: $global-border-radius;
  padding:       15px;
  margin:        0 5px;
  width:         94px;
  height:        94px;
  box-sizing:    border-box;

  &:hover {
    box-shadow: 0 2px 4px $box-shadow-color;
  }
}

.redirect {
  text-align:      center;
  display:         flex;
  flex-direction:  column;
  justify-content: center;
  margin:          0 auto;
  width:           200px;
  align-items:     center;
}

.logged-out {
  display:     flex;
  align-items: center;
  width:       500px;
  margin:      0 auto;
  background:  $white;
  padding:     30px;
  box-shadow:  1px 1px 10px $boxshadow;

  p {
    margin-bottom: 0;
  }

  a {
    color:           $link-color;
    text-decoration: none;
  }
}

.logout-graphics {
  width:        70px;
  margin-right: 30px;
}

.error-box {
  padding:  10px 0;
  position: absolute;
  left:     30px;
  right:    30px;
  top:      0;

}

